import momemt from 'moment';
jQuery( document ).ready(function($) {
  // navigation
  $('.section-trigger').click(function(e){
    e.preventDefault()
    const target = $(this).attr('href')
    $('section').not(target).removeClass('active')
    $(target).addClass('active')
  })
  // events
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Khamari/events?app_id=45PRESS_KHAMARI',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert( 'Error fetching events!' ); 
    },
    success: data => {
    const events = $( '#events' );
    let html = '';
    if ( data.length ) {
      for ( let event of data ) {
        let region = event.venue.region?event.venue.region:event.venue.country;
        let location = event.venue.city + ', ' + region;
        if ( location === ', ' ) {location = '';}
        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
        html += '<div class="event">';
        html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
        html += '<div class="event-location">' + location;
        html += '<div class="event-venue">' + event.venue.name + '</div></div>';
        html += '<div class="event-links">';
        for ( let offer of event.offers ) {
          html += '<a href="' + offer.url + '" target="_blank" class="btn btn-border">' + offer.type + '</a>';
        }
        html += '</div>';
        html += '</div>';
      }
      events.html( html );
      } else {
        events.html( 'No upcoming events.' );
      }
    }
  });
  // newsletter
  $("#khamari-email-list").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('.form-alert.success').show();
        $('#khamari-email-list, .form-alert.error').hide();
      },
      error: function (err) {
        $('.form-alert.error').show();
      }
    });
  });
});